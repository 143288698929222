import {
  Alerter,
  Button,
  Card,
  CardLevel,
  Checkbox,
  Col,
  Drawer,
  Empty,
  FormGroup,
  Frow,
  ISelectOption,
  IconButton40,
  IconNames16,
  Input,
  Intent,
  Menu,
  MenuItem,
  PaddingLevel,
  Popover,
  RadioSelect,
  Select,
} from '@pinpointhq/thumbtack';
import * as React from 'react';
import { ReportTemplate } from '../../../javascript/models';
import { createToast } from '../../FlashToaster';
import { DrawerFooter } from '../../shared/DrawerFooter';
import { DrawerHeader } from '../../shared/DrawerHeader';
import { Column } from './reportForm/Column';

export function ReportForm({
  handleClose,
  fetchData,
  fetchReportData,
  reportTemplate,
  reportMeta,
  superAdmin,
}: {
  handleClose(): void;
  fetchData(): void;
  fetchReportData(): void;
  reportTemplate: ReportTemplate;
  reportMeta: any;
  superAdmin: boolean;
}) {
  const [reportType, selectedReportType] = React.useState<string>(reportTemplate.reportType);
  const [name, setName] = React.useState<string>(reportTemplate.name);
  const [columns, setColumns] = React.useState(reportTemplate.columns);
  const [singleUse, setSingleUse] = React.useState<string>(reportTemplate.singleUse ? '1' : '0');
  const [filters, setFilters] = React.useState(reportTemplate.filters);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);

  const allSelectOptions = reportMeta.stats.columns[reportType] || [];
  const filteredSelectOptions = allSelectOptions.filter((option: ISelectOption) => {
    const columnValues = columns.map((column) => column.value);
    return !columnValues.includes(option.value);
  });

  const handleChange = (item: ISelectOption) => {
    setColumns([...columns, item]);
  };

  const handleRemoveColumn = (index: number) => {
    const updatedColumns = columns.filter((_, i) => i !== index);
    setColumns(updatedColumns);
  };

  const handleMoveUp = (index: number) => {
    const result = [...columns];
    const [removed] = result.splice(index, 1);
    result.splice(index - 1, 0, removed);

    setColumns(result);
  };

  const handleMoveDown = (index: number) => {
    const result = [...columns];
    const [removed] = result.splice(index, 1);
    result.splice(index + 1, 0, removed);

    setColumns(result);
  };

  const handleNameChange = (event) => {
    setName(event.target.value);
  };

  const removeFilter = (index) => {
    const updatedFilters = filters.filter((_, i) => i !== index);
    setFilters(updatedFilters);
  };

  const handleAddFilter = (property, value, operator) => {
    if (filters.some((filter) => filter.property === property && filter.value === value)) {
      return;
    }
    const newFilter = { property, operator, value };
    setFilters([...filters, newFilter]);
  };

  const handleAddFilterCycle1 = () => {
    handleAddFilter('cycle', '2021/22', 'eq');
  };

  const handleAddFilterCycle2 = () => {
    handleAddFilter('cycle', '2022/23', 'eq');
  };

  const handleAddFilterCycle3 = () => {
    handleAddFilter('cycle', '2023/24', 'eq');
  };

  const handleAddFilterCycle4 = () => {
    handleAddFilter('cycle', '2024/25', 'eq');
  };

  const handleAddFilterProgramme1 = () => {
    handleAddFilter('programme', '10kbi', 'eq');
  };

  const handleAddFilterProgramme2 = () => {
    handleAddFilter('programme', '10kai', 'eq');
  };

  const handleSave = () => {
    setIsSubmitting(true);
    reportTemplate.name = name;
    reportTemplate.columns = columns;
    reportTemplate.filters = filters;
    reportTemplate.reportType = reportType;
    reportTemplate.singleUse = singleUse === '1';
    reportTemplate
      .save()
      .then((success) => {
        if (success) {
          if (reportTemplate.singleUse) {
            createToast({ text: 'Report successfully created', type: 'success' });
            fetchReportData();
          } else {
            createToast({ text: 'Template successfully saved', type: 'success' });
          }
          handleClose();
          fetchData();
        } else {
          createToast({ text: 'Unable to create template', type: 'error' });
          setIsSubmitting(false);
        }
      })
      .catch(() => {
        createToast({ text: 'Unable to create template', type: 'error' });
        setIsSubmitting(false);
      });
  };

  const radios = [
    { value: '1', label: 'Report only' },
    { value: '0', label: 'Report template' },
  ].map((option) => {
    const handleChange = () => {
      setSingleUse(option.value);
    };

    return (
      <div className="col-flex-grow-1" key={option.value}>
        <RadioSelect
          disabled={option.value === '0' && !superAdmin}
          label={option.label}
          onChange={handleChange}
          checked={singleUse === option.value}
        />
      </div>
    );
  });

  return (
    <Drawer maxWidth={1200} isOpen={true}>
      <DrawerHeader title="Edit Report" handleClose={handleClose} />
      <Card level={CardLevel.NONE} paddingLevel={PaddingLevel.EXPANDED} style={{ flexGrow: 1, overflowY: 'scroll' }}>
        <FormGroup label="Report name">
          <Input value={name} fill={true} onChange={handleNameChange} />
        </FormGroup>
        <FormGroup label="Template">
          <Frow flexDirection="row" flexWrap="nowrap" justifyContent="center">
            {radios}
          </Frow>
        </FormGroup>
        <FormGroup label="Report type">
          <Frow gutters={16} verticalGutters={16}>
            {reportMeta.stats.options.report_types.map((type: ISelectOption) => {
              const handleClick = () => {
                if (columns.length > 0 && reportType !== type.value) {
                  Alerter.create({
                    cancelButtonText: 'Cancel',
                    confirmButtonText: 'Yes',
                    intent: Intent.DANGER,
                    onConfirm: () => {
                      selectedReportType(type.value);
                      setColumns([]);
                      setFilters([]);
                    },
                    text:
                      'Changing the report type will reset the columns and filters. Are you sure you want to continue?',
                  });
                } else {
                  selectedReportType(type.value);
                  setFilters([]);
                }
              };

              return (
                <Col key={type.value} md={{ width: '1-3' }}>
                  <Card
                    level={CardLevel.RAISED}
                    paddingLevel={PaddingLevel.NONE}
                    interactive={true}
                    onClick={handleClick}
                  >
                    <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.REGULAR}>
                      <Frow gutters={8} verticalGutters={8} alignItems="center" justifyContent="space-between">
                        <div>{type.label}</div>
                        <div>
                          <Checkbox
                            style={{ marginBottom: 0 }}
                            checked={reportType === type.value}
                            onChange={handleClick}
                          />
                        </div>
                      </Frow>
                    </Card>
                  </Card>
                </Col>
              );
            })}
          </Frow>
        </FormGroup>
        <Frow gutters={16} verticalGutters={16}>
          <Col md={{ width: '1-2' }}>
            <Card level={CardLevel.OUTLINED} paddingLevel={PaddingLevel.CONDENSED} style={{ height: '100%' }}>
              <FormGroup label="Columns">
                <Select
                  fill={true}
                  buttonProps={{ large: true }}
                  items={filteredSelectOptions}
                  activeItem={null}
                  onItemSelect={handleChange}
                  disabled={!reportType}
                  resetOnSelect={false}
                />
              </FormGroup>
              {columns.length > 0 ? (
                <Frow verticalGutters={16}>
                  {columns.map((column, index) => {
                    return (
                      <Column
                        key={column.value}
                        column={column}
                        index={index}
                        handleRemoveColumn={handleRemoveColumn}
                        handleMoveDown={handleMoveDown}
                        handleMoveUp={handleMoveUp}
                      />
                    );
                  })}
                </Frow>
              ) : (
                <Empty text="No columns selected" compact={true} />
              )}
            </Card>
          </Col>
          <Col md={{ width: '1-2' }}>
            <Card level={CardLevel.OUTLINED} paddingLevel={PaddingLevel.CONDENSED} style={{ height: '100%' }}>
              <FormGroup label="Filters" />
              {filters.length > 0 ? (
                <Frow verticalGutters={16}>
                  {filters.map((filter, index) => {
                    const handleRemoveFilter = () => {
                      removeFilter(index);
                    };

                    return (
                      <Col key={filter.property}>
                        <Card level={CardLevel.RAISED} paddingLevel={PaddingLevel.NONE}>
                          <Card level={CardLevel.FILLED} paddingLevel={PaddingLevel.CONDENSED}>
                            <Frow alignItems="center" justifyContent="space-between">
                              <div>
                                {filter.property} {filter.operator} {filter.value}
                              </div>
                              <div>
                                <IconButton40
                                  icon={IconNames16.CLOSE}
                                  aria-label="Remove"
                                  onClick={handleRemoveFilter}
                                />
                              </div>
                            </Frow>
                          </Card>
                        </Card>
                      </Col>
                    );
                  })}
                </Frow>
              ) : (
                <Empty text="No filters applied" compact={true} />
              )}
              <Popover targetProps={{ style: { width: '100%' } }} autoFocus={false} enforceFocus={false}>
                <Button className="mar-t-1" text="Add Filter" icon={IconNames16.ADD} fill={true} minimal={true} />
                <Menu>
                  <MenuItem text="Cycle">
                    <MenuItem text="2021/22" onClick={handleAddFilterCycle1} />
                    <MenuItem text="2022/23" onClick={handleAddFilterCycle2} />
                    <MenuItem text="2023/24" onClick={handleAddFilterCycle3} />
                    <MenuItem text="2024/25" onClick={handleAddFilterCycle4} />
                  </MenuItem>
                  <MenuItem text="Programme">
                    <MenuItem text="10KBI" onClick={handleAddFilterProgramme1} />
                    <MenuItem text="10KAI" onClick={handleAddFilterProgramme2} />
                  </MenuItem>
                  {reportType === 'candidate_applications' && (
                    <MenuItem text="Eligibility">
                      <MenuItem text="Eligible" onClick={() => handleAddFilter('eligible', 'true', 'eq')} />
                      <MenuItem text="Ineligible" onClick={() => handleAddFilter('eligible', 'false', 'eq')} />
                    </MenuItem>
                  )}
                  {reportType === 'contacts' && (
                    <MenuItem text="Status">
                      <MenuItem text="Active" onClick={() => handleAddFilter('status', 'active', 'eq')} />
                      <MenuItem text="Inactive" onClick={() => handleAddFilter('status', 'inactive', 'eq')} />
                    </MenuItem>
                  )}
                </Menu>
              </Popover>
            </Card>
          </Col>
        </Frow>
      </Card>
      <DrawerFooter>
        <Frow gutters={16}>
          <div>
            <Button text="Cancel" minimal={true} onClick={handleClose} disabled={isSubmitting} />
          </div>
          <div>
            <Button text="Save" onClick={handleSave} disabled={isSubmitting} />
          </div>
        </Frow>
      </DrawerFooter>
    </Drawer>
  );
}
